import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Button from '../components/Button';
import Text from '../components/Text';
import { fetchAdequatePixel } from '../utils/datautils';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  /* or 162% */
  background: none;
  letter-spacing: 0.15px;

  /* gray 9 */
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const InputWorryPage = ({ value, onChange, onButtonClick }) => {
  const pixelId = fetchAdequatePixel();
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    fbq('trackSingle', pixelId, 'ViewContent');

    amplitude.getInstance().logEvent('page_second', { referral: 'postpay_painful_saju_compat' });
  }, []);

  return (
    <>
      <Text style={{ width: '100%' }}>
        아래 정보를 <br /> 순서대로 작성해주세요.
        <br />
        <br />
        연인과의 궁합을 보기 원하시면, 연인의 정보도 동일한 양식으로 추가해주세요.
        <br />
        1. 성함
        <br />
        2. 생년월일과 태어난 시간
        <br />
        3. 특별한 고민
      </Text>

      <Input placeholder={'위 정보를 최대한 자세히 입력해주세요'} value={value} onChange={onChange} />
      <div
        style={{
          marginTop: '1.6rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          id="cta-finish-writing"
          text="입력 완료"
          width="50%"
          color="#fff"
          backgroundColor={value.length >= 5 ? '#222529' : '#CFD4DA'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
          }}
          onClickHandler={
            value.length >= 5
              ? () => {
                  fbq('trackSingle', pixelId, 'SubmitApplication', { value: 126, currency: 'KRW' });
                  onButtonClick();
                }
              : () => {}
          }
        />
      </div>
    </>
  );
};

export default InputWorryPage;
